import React from 'react';

const EarlyTrending = ({ trendingTokens }) => {
  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const formatNumber = (num) => {
    return num?.toLocaleString() || '-';
  };

    // Add custom styles
    const tableStyles = {
      fontSize: '0.85rem',  // Reduce overall font size
    };
  
    const headerStyles = {
      fontSize: '0.9rem',
      fontWeight: 'bold',
    };
  
    const cellStyles = {
      fontSize: '0.85rem',
      padding: '0.5rem',  // Reduce cell padding to make the table more compact
    };

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="table-responsive">
          <table className="table table-hover table-striped mb-0" style={tableStyles}>
            <thead>
              <tr>
                <th style={headerStyles}>Token</th>
                <th style={headerStyles}>Find_MC</th>
                <th style={headerStyles}>Find_ATH</th>
                <th style={headerStyles}>Find_Hol</th>
                <th style={headerStyles}>Find_Volume</th>
                <th style={headerStyles}>Find_Txs</th>
                <th style={headerStyles}>Find_PC</th>
                <th style={headerStyles}>CreatedAt</th>
                <th style={headerStyles}>Profit</th>
                <th style={headerStyles}>Profit_MC</th>
                <th style={headerStyles}>LastUpdate</th>
                <th style={headerStyles}>Chart</th>
              </tr>
            </thead>
            <tbody>
              {trendingTokens.map((token) => (
                <tr key={token._id}>
                  <td style={cellStyles}>{`${token.tokenName} , $${token.symbol || ''} \n`} 
                        <a href={'https://gmgn.ai/sol/token/'+token.contractAddress} className="btn btn-sm btn-light me-1" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-line-chart"></i>
                      </a>
                  </td>
                  <td style={cellStyles}>${formatNumber(token.initialMessage.marketCap)}</td>
                  <td style={cellStyles}>${formatNumber(token.initialMessage.ATH)}</td>
                  <td style={cellStyles}>{formatNumber(token.initialMessage.holders)}</td>
                  <td style={cellStyles}>${formatNumber(token.initialMessage.volume1h)}</td>
                  <td style={cellStyles}>{token.txns1h || '-'}</td>
                  <td style={cellStyles}>{token.priceChange5m1h || '-'}</td>
                  <td style={cellStyles}>{formatDate(token.initialMessage.timestamp)}</td>
                  <td style={cellStyles}>{token.highestMultiplier ? formatNumber(token.highestMultiplier.toFixed(2)) + 'x' : '-'}</td>
                  <td style={cellStyles}>{token.highestMarketCap ? '$'+formatNumber(token.highestMarketCap) : '-'}</td>
                  <td style={cellStyles}>{token.highestMultiplier ? formatDate(token.lastUpdateTimestamp) : '-'}</td>
                  <td style={cellStyles}>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EarlyTrending;